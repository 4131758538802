import { Component } from 'react';
import PropTypes from 'prop-types';
import {
  faStar as faStartSolid,
  faStarHalfAlt,
  faStarHalf,
  faTimes as faTimesSolid,
  faExternalLink,
  faListAlt,
  faAddressCard,
  faQuestionSquare,
  faIdBadge,
  faArrowLeft,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faChevronDown as faChevronDownSolid,
  faCheck,
  faGripHorizontal,
  faArrowRight as faArrowRightSolid,
  faCreditCard,
  faComment,
  faCommentAlt,
  faShoppingBasket,
  faWifiSlash,
  faShoppingCart as faShoppingCartSolid,
  faEllipsisH,
  faEllipsisV
} from '@fortawesome/pro-solid-svg-icons';

import {
  faPlusCircle as faPlusCircleLight,
  faSearch,
  faUserCircle,
  faTimes as faTimesLight,
  faArrowRight as faArrowRightLight,
  faArrowLeft as faArrowLeftLight,
  faTrashAlt as faTrashAltLight,
  faChevronLeft as faChevronLeftLight,
  faChevronRight as faChevronRightLight,
  faSortAlt as faSortAltLight,
  faFileCertificate as faFileCertificateLight,
  faHouse as faHouseLight,
  faBoxCheck,
  faUser,
  faStar as faStarLight,
  faChartLine
} from '@fortawesome/pro-light-svg-icons';

import {
  faStar,
  faChevronLeft,
  faChevronRight,
  faShoppingCart,
  faLock,
  faEnvelope,
  faClock,
  faFilter as faFilterRegular,
  faArrowRight as faArrowRightRegular,
  faPlus as faPlusRegular,
  faMinus as faMinusRegular,
  faTimes,
  faFileCertificate,
  faSortAlt,
  faSortAmountDown,
  faList,
  faTh,
  faCheck as faCheckRegular
} from '@fortawesome/pro-regular-svg-icons';

import {
  faFacebookSquare,
  faYoutube,
  faInstagram,
  faTwitter,
  faFacebookF,
  faLinkedinIn
} from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faStar,
  faStartSolid,
  faStarHalf,
  faStarHalfAlt,
  faTimes,
  faTimesLight,
  faTimesSolid,
  faExternalLink,
  faChevronLeft,
  faChevronRight,
  faClock,
  faAddressCard,
  faQuestionSquare,
  faPlusCircleLight,
  faListAlt,
  faIdBadge,
  faArrowLeft,
  faShoppingCart,
  faLock,
  faChevronLeftSolid,
  faChevronRightSolid,
  faChevronDownSolid,
  faEnvelope,
  faFacebookSquare,
  faYoutube,
  faInstagram,
  faTwitter,
  faCheck,
  faFilterRegular,
  faGripHorizontal,
  faFacebookSquare,
  faYoutube,
  faInstagram,
  faTwitter,
  faCheckRegular,
  faFacebookF,
  faLinkedinIn,
  faSearch,
  faUserCircle,
  faArrowRightLight,
  faTrashAltLight,
  faArrowRightRegular,
  faChevronLeftLight,
  faChevronRightLight,
  faMinusRegular,
  faPlusRegular,
  faArrowRightSolid,
  faShoppingCartSolid,
  faCreditCard,
  faWifiSlash,
  faComment,
  faCommentAlt,
  faShoppingBasket,
  faFileCertificate,
  faFileCertificateLight,
  faSortAltLight,
  faSortAlt,
  faSortAmountDown,
  faList,
  faTh,
  faArrowLeftLight,
  faHouseLight,
  faBoxCheck,
  faUser,
  faEllipsisH,
  faEllipsisV,
  faStarLight,
  faChartLine
);

class Index extends Component {
  render() {
    let className = 'fontawesome-icon';
    if (this.props.active) className += ' fontawesome-icon-active';
    if (this.props.className) className += ` ${this.props.className}`;

    const iconConfig = {
      iconName: this.props.iconName,
      prefix: this.props.prefix
    };

    return (
      <FontAwesomeIcon
        className={className}
        onClick={e => this.props.onClick && this.props.onClick(e)}
        icon={this.props.prefix ? iconConfig : this.props.iconName}
      />
    );
  }
}

Index.propTypes = {
  active: PropTypes.bool,
  prefix: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  menuIcon: PropTypes.bool
};

export default Index;
