import EventAgent from '@hotmart/event-agent-js';

import { REQUIRED_FIELDS, EVENT_PROPS } from './datahub.constants';

const getCommonEventDataByAction = (action : string) => {
  const eventData = EVENT_PROPS[action];

  if (!eventData && process.env.ENV_CONFIG !== 'production') {
    throw new Error(`Invalid datahub action. Received: ${action}`);
  }

  return { ...eventData } as DataHubAction;
};

const validateEventRequiredFields = (event : DataHubAction, eventData : DataHubAction) => {
  const actionRequiredFields = REQUIRED_FIELDS[event.action];
  const isInvalid = actionRequiredFields.some(field => !event[field]);

  if (isInvalid && process.env.ENV_CONFIG !== 'production') {
    console.error(`Invalid datahub event data. Received: ${JSON.stringify(eventData, null, 2)}`);
  }

  return isInvalid;
};

export function sendEvent(event : DataHubAction) {
  const { action, ...restEvent } = event;

  const eventData = getCommonEventDataByAction(action);

  eventData.event = restEvent;
  const isInvalid = validateEventRequiredFields(event, eventData);
  if (restEvent.data_version) {
    eventData.data_version = restEvent.data_version;
  }

  if (!isInvalid && process.env.ENV_CONFIG !== 'production') {
    // eslint-disable-next-line no-console
    console.log('DATAHUB EVENT: ', JSON.stringify(eventData, null, 2));
  }

  if (!isInvalid) {
    EventAgent.mode = process.env.ENV_CONFIG === 'production' ? 'production' : 'staging';
    EventAgent.send(eventData);
  }
}
