import { TFunction } from 'next-i18next';
import { getCommonByLanguage } from 'static/locales/common';

export const translateSuggestionsList = (t : TFunction, list : string[]) => {
  if (list && Array.isArray(list)) {
    return list.reduce((acc, item) => {
      if (!item) return acc;
      return [...acc, { key: item, value: t(`search.terms.${item}`) }];
    }, [] as { key : string, value : string }[]);
  }
  return [];
};

export const loadSearchMostWanted = (t : TFunction, language : string, categories : string[]) => {
  const localizedString = getCommonByLanguage(language);

  const {
    mostWanted = [],
    mostWantedEducation = [],
    mostWantedPersonalDevelopment = [],
    mostWantedBussinessCareers = [],
    mostWantedHealthSports = [],
    mostWantedGastronomy = [],
    mostWantedFinaces = []
  } = localizedString;

  const categoriesWithMostWantedValueAvailable = {
    education: mostWantedEducation,
    gastronomy: mostWantedGastronomy,
    finance: mostWantedFinaces,
    business_and_careers: mostWantedBussinessCareers,
    personal_development: mostWantedPersonalDevelopment,
    health_and_sports: mostWantedHealthSports
  };

  let mostWantedWords = [];

  if (categories && Array.isArray(categories) && categories.length > 0) {
    categories.forEach(category => {

      if (categoriesWithMostWantedValueAvailable[category]) {
        mostWantedWords = [
          ...mostWantedWords,
          ...categoriesWithMostWantedValueAvailable[category]
        ];
      }
    });
  }

  if (mostWantedWords.length === 0) {
    mostWantedWords = mostWanted;
  }

  const suggestionsList = translateSuggestionsList(t, mostWantedWords);

  return {
    suggestions: suggestionsList
  };
};

export const loadSearchData = (t : TFunction, language : string) => {
  const localizedString = getCommonByLanguage(language);
  const {
    suggestions
  } = localizedString;

  // translate suggestions
  const suggestionsList = translateSuggestionsList(t, suggestions);

  return {
    placeholder: `${t('search.what_would_you_like_to_learn')}`,
    suggestions: suggestionsList
  };
};
