import { LANGUAGES } from '@core/domain/constants/topification';

export const verifyLanguage = (language?: string) => {
  if (!language) return LANGUAGES.EN;
  const lowerLanguage = language.toString().toLowerCase() as Language;

  if (lowerLanguage.match(/^pt/)) {
    return LANGUAGES.PT_PR;
  }

  if (lowerLanguage.match(/^en/)) {
    return LANGUAGES.EN;
  }

  if (lowerLanguage.match(/^es/)) {
    return LANGUAGES.ES;
  }

  if (lowerLanguage.match(/^fr/)) {
    return LANGUAGES.FR;
  }

  return LANGUAGES.EN;
};
