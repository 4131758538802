export enum CAMPAIGN_PT_BR {
  HEALTH_AND_SPORTS = 'saude-e-esportes-evergreen',
  CHANGE_YOUR_CARRER = 'transforme-sua-carreira',
  MUSIC_STUDIO = 'estudio-de-musica',
  COOKING_IN_PRACTICE = 'cozinha-na-pratica',
  FASHION_AND_BEAUTY = 'moda-e-beleza',
  YOUR_FINANCES = 'suas-financas'
}

export enum CAMPAIGN_ES {
  COOKING_IN_PRACTICE = 'cocina-en-la-practica',
  FASHION_AND_BEAUTY = 'moda-y-belleza',
  YOUR_FINANCES = 'tus-finanzas'
}
