export const sendAnalyticsEvent = (analyticsEvent : AnalyticsEvent) => {
  try {
    if (typeof window !== 'undefined' && analyticsEvent) {
      window.dataLayer = window.dataLayer || [];
      if (analyticsEvent.language) {
        analyticsEvent.language = `${analyticsEvent.language || ''}`.toLowerCase();
      }
      analyticsEvent.deploymentEnvironment = `${process.env.ENV_CONFIG || ''}`;
      if (process.env.ENV_CONFIG !== 'production') {
        console.log(
          'ANALYTICS EVENT: ',
          JSON.stringify(analyticsEvent, null, 2)
        );
      }
      window.dataLayer.push(analyticsEvent);
    }
  } catch (err) {
    console.log('err', err);
  }
};

export const checkoutCourseAnalytics = () => {
  sendAnalyticsEvent({
    event: 'interaction',
    event_category: 'Marketplace:Home',
    event_action: 'Click:BlackNovember:Courses',
    event_label: 'Courses'
  });
};
