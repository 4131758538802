import {
  getTopificationRouter,
  normalizeTopificationUrl,
  normalizeTopicUrl
} from '@core/presentation/utils/topification/';
import {
  mountMarketplaceResultsPage,
  generateURLQueryString,
  mountMarketplaceUserPage,
  generateCampaignByLanguage
} from '../../../../utils/url';
import { envConfig } from '../../../../../env.config';

export function treatedPageURL(language: Language, query: any, asPath: string) {
  if (query?.category?.match(/categories|categorias/g)) {
    const topificationNormalized = normalizeTopificationUrl(query?.slug[0]);
    const topicNormalized = normalizeTopicUrl(query?.slug[1]);
    const urlFinal = getTopificationRouter({
      language,
      topification: topificationNormalized,
      topic: topicNormalized
    });

    return urlFinal;
  }
  if (query?.category?.match(/produtos|produicts|products|productos/g)) {
    const urlResults = mountMarketplaceResultsPage(language);
    const urlFinal = generateURLQueryString(urlResults, { ...query });

    return urlFinal;
  }
  if (query.slug) {
    return mountMarketplaceUserPage(language, query);
  }
  if (query?.season) {
    return generateCampaignByLanguage(language, query.season);
  }

  return `${envConfig.APP.URL}/${language}${asPath}`;
}
