import {
  TOPIC_KEY,
  TOPIFICATION_KEY,
  TOPIFICATION_ROUTER
} from '@core/domain/constants/topification';
import { verifyLanguage } from '@core/presentation/utils/language';
import { CAMPAIGN_PT_BR, CAMPAIGN_ES } from '@core/domain/constants/campaign';
import { envConfig } from '../../../../../env.config';
import { TOPIC_TRANSLATION, TOPIFICATION_TRANSLATION } from './translation';

export const getTopificationRouter = (params: {
  language: Language;
  topification: TOPIFICATION_KEY;
  topic?: TOPIC_KEY | null;
}) => {
  const verifiedLang = verifyLanguage(params.language);
  const baseUrl = `${envConfig.APP.URL}/${verifiedLang}/marketplace/${
    TOPIFICATION_ROUTER[verifiedLang]
  }/${TOPIFICATION_TRANSLATION[verifiedLang][params.topification]}`;
  if (!params.topic) return baseUrl;

  return `${baseUrl}/${TOPIC_TRANSLATION[verifiedLang][params.topic]}`;
};

export const normalizeTopificationUrl = (topification?: string) => {
  switch (topification) {
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.ACADEMIC_STUDIES]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.ACADEMIC_STUDIES]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.ACADEMIC_STUDIES]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.ACADEMIC_STUDIES]:
      return TOPIFICATION_KEY.ACADEMIC_STUDIES;
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.ANIMALS_AND_PETS]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.ANIMALS_AND_PETS]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.ANIMALS_AND_PETS]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.ANIMALS_AND_PETS]:
      return TOPIFICATION_KEY.ANIMALS_AND_PETS;
    case TOPIFICATION_TRANSLATION['pt-br'][
      TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT
    ]:
    case TOPIFICATION_TRANSLATION.es[
      TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT
    ]:
    case TOPIFICATION_TRANSLATION.en[
      TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT
    ]:
    case TOPIFICATION_TRANSLATION.fr[
      TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT
    ]:
      return TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT;
    case TOPIFICATION_TRANSLATION['pt-br'][
      TOPIFICATION_KEY.CHILDHOOD_AND_FAMILY
    ]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.CHILDHOOD_AND_FAMILY]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.CHILDHOOD_AND_FAMILY]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.CHILDHOOD_AND_FAMILY]:
      return TOPIFICATION_KEY.CHILDHOOD_AND_FAMILY;
    case TOPIFICATION_TRANSLATION['pt-br'][
      TOPIFICATION_KEY.COOKING_AND_GASTRONOMY
    ]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.COOKING_AND_GASTRONOMY]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.COOKING_AND_GASTRONOMY]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.COOKING_AND_GASTRONOMY]:
      return TOPIFICATION_KEY.COOKING_AND_GASTRONOMY;
    case TOPIFICATION_TRANSLATION['pt-br'][
      TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY
    ]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY]:
      return TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY;
    case TOPIFICATION_TRANSLATION['pt-br'][
      TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE
    ]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE]:
      return TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE;
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.FASHION_AND_BEAUTY]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.FASHION_AND_BEAUTY]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.FASHION_AND_BEAUTY]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.FASHION_AND_BEAUTY]:
      return TOPIFICATION_KEY.FASHION_AND_BEAUTY;
    case TOPIFICATION_TRANSLATION['pt-br'][
      TOPIFICATION_KEY.FINANCE_AND_BUSINESS
    ]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.FINANCE_AND_BUSINESS]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.FINANCE_AND_BUSINESS]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.FINANCE_AND_BUSINESS]:
      return TOPIFICATION_KEY.FINANCE_AND_BUSINESS;
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.HEALTH_AND_SPORTS]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.HEALTH_AND_SPORTS]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.HEALTH_AND_SPORTS]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.HEALTH_AND_SPORTS]:
      return TOPIFICATION_KEY.HEALTH_AND_SPORTS;
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.HOBBY_AND_LEISURE]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.HOBBY_AND_LEISURE]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.HOBBY_AND_LEISURE]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.HOBBY_AND_LEISURE]:
      return TOPIFICATION_KEY.HOBBY_AND_LEISURE;
    case TOPIFICATION_TRANSLATION['pt-br'][
      TOPIFICATION_KEY.MAINTENANCE_AND_REPAIR
    ]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.MAINTENANCE_AND_REPAIR]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.MAINTENANCE_AND_REPAIR]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.MAINTENANCE_AND_REPAIR]:
      return TOPIFICATION_KEY.MAINTENANCE_AND_REPAIR;
    case TOPIFICATION_TRANSLATION['pt-br'][
      TOPIFICATION_KEY.MARKETING_AND_SALES
    ]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.MARKETING_AND_SALES]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.MARKETING_AND_SALES]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.MARKETING_AND_SALES]:
      return TOPIFICATION_KEY.MARKETING_AND_SALES;
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.MUSIC_AND_ARTS]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.MUSIC_AND_ARTS]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.MUSIC_AND_ARTS]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.MUSIC_AND_ARTS]:
      return TOPIFICATION_KEY.MUSIC_AND_ARTS;
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.PLANTS_AND_ECOLOGY]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.PLANTS_AND_ECOLOGY]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.PLANTS_AND_ECOLOGY]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.PLANTS_AND_ECOLOGY]:
      return TOPIFICATION_KEY.PLANTS_AND_ECOLOGY;
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.RELATIONSHIPS]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.RELATIONSHIPS]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.RELATIONSHIPS]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.RELATIONSHIPS]:
      return TOPIFICATION_KEY.RELATIONSHIPS;
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.SELF_KNOWLEDGE]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.SELF_KNOWLEDGE]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.SELF_KNOWLEDGE]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.SELF_KNOWLEDGE]:
      return TOPIFICATION_KEY.SELF_KNOWLEDGE;
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.TECHNOLOGY]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.TECHNOLOGY]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.TECHNOLOGY]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.TECHNOLOGY]:
      return TOPIFICATION_KEY.TECHNOLOGY;
    case TOPIFICATION_TRANSLATION['pt-br'][TOPIFICATION_KEY.UNMAPPING_FIELD]:
    case TOPIFICATION_TRANSLATION.es[TOPIFICATION_KEY.UNMAPPING_FIELD]:
    case TOPIFICATION_TRANSLATION.en[TOPIFICATION_KEY.UNMAPPING_FIELD]:
    case TOPIFICATION_TRANSLATION.fr[TOPIFICATION_KEY.UNMAPPING_FIELD]:
      return TOPIFICATION_KEY.UNMAPPING_FIELD;
    default:
      return null;
  }
};

export const normalizeOldCategories = (category: string) => {
  switch (category) {
    case 'educacao':
    case 'educacion':
    case 'education':
      return { topification: TOPIFICATION_KEY.ACADEMIC_STUDIES };
    case 'desenvolvimento-pessoal':
    case 'desarrollo-personal':
    case 'developpement-personnel':
    case 'personal-development':
    case 'negocios-e-carreira':
    case 'negocios-y-carrera':
    case 'affaires-et-carriere':
    case 'business-and-careers':
      return { topification: TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT };
    case 'financas-e-investimentos':
    case 'finanzas-e-inversiones':
    case 'finances-et-investissements':
    case 'finance-and-investments':
      return { topification: TOPIFICATION_KEY.FINANCE_AND_BUSINESS };
    case 'gastronomy':
    case 'gastronomia':
    case 'gastronomie':
    case 'cuisine-et-gastronomie':
      return { topification: TOPIFICATION_KEY.COOKING_AND_GASTRONOMY };
    case 'animais-e-plantas':
    case 'animaux-et-plantes':
    case 'animales-y-plantas':
    case 'animals-and-plants':
      return { topification: TOPIFICATION_KEY.ANIMALS_AND_PETS };
    case 'derecho':
    case 'direito':
    case 'droit':
    case 'law':
      return {
        topification: TOPIFICATION_KEY.ACADEMIC_STUDIES,
        topic: TOPIC_KEY.LAW
      };
    case 'espiritualidade':
    case 'espiritualidad':
    case 'spiritualite':
    case 'spirituality':
      return { topification: TOPIFICATION_KEY.SELF_KNOWLEDGE };
    case 'literatura':
    case 'litterature':
    case 'literature':
      return {
        topification: TOPIFICATION_KEY.ACADEMIC_STUDIES,
        topic: TOPIC_KEY.LITERATURE
      };
    case 'apps-e-software':
    case 'apps-y-software':
    case 'appli-et-logiciels':
    case 'apps-and-software':
      return { topification: TOPIFICATION_KEY.TECHNOLOGY };
    case 'environment':
    case 'ecologia-e-meio-ambiente':
    case 'ecologia-y-medio-ambiente':
    case 'ecologie-et-environnement':
      return { topification: TOPIFICATION_KEY.PLANTS_AND_ECOLOGY };
    case 'idioms':
    case 'idiomas':
    case 'langues':
      return {
        topification: TOPIFICATION_KEY.ACADEMIC_STUDIES,
        topic: TOPIC_KEY.LITERATURE
      };
    case 'musica-e-artes':
    case 'arts-and-music':
    case 'musica-y-artes':
    case 'arts-et-musique':
      return { topification: TOPIFICATION_KEY.MUSIC_AND_ARTS };
    case 'tecnologia-da-informacao':
    case 'information-technology':
    case 'tecnologia-de-la-informacion':
    case 'technologies-de-l-information':
    case 'internet':
      return { topification: TOPIFICATION_KEY.TECHNOLOGY };
    case 'casa-e-construcao':
    case 'building-and-constructing':
    case 'casa-y-construccion':
    case 'maison-et-construction':
      return { topification: TOPIFICATION_KEY.ENGINEERING_ARCHITECTURE };
    case 'design':
    case 'diseno':
      return { topification: TOPIFICATION_KEY.DESIGN_AND_PHOTOGRAPHY };
    case 'geral':
    case 'general':
      return { topification: TOPIFICATION_KEY.UNMAPPING_FIELD };
    default:
      return { topification: null };
  }
};

export const normalizeTopicUrl = (topic?: string) => {
  switch (topic) {
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.ACCOUNTING]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.ACCOUNTING]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.ACCOUNTING]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.ACCOUNTING]:
      return TOPIC_KEY.ACCOUNTING;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.AGRICULTURE]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.AGRICULTURE]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.AGRICULTURE]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.AGRICULTURE]:
      return TOPIC_KEY.AGRICULTURE;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.ARCHITECTURE]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.ARCHITECTURE]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.ARCHITECTURE]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.ARCHITECTURE]:
      return TOPIC_KEY.ARCHITECTURE;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.ASTROLOGY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.ASTROLOGY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.ASTROLOGY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.ASTROLOGY]:
      return TOPIC_KEY.ASTROLOGY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.CARRER]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.CARRER]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.CARRER]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.CARRER]:
      return TOPIC_KEY.CARRER;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.COACHING]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.COACHING]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.COACHING]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.COACHING]:
      return TOPIC_KEY.COACHING;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.CONFECTIONERY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.CONFECTIONERY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.CONFECTIONERY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.CONFECTIONERY]:
      return TOPIC_KEY.CONFECTIONERY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.DESIGN]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.DESIGN]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.DESIGN]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.DESIGN]:
      return TOPIC_KEY.DESIGN;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.DIGITAL_MARKETING]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.DIGITAL_MARKETING]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.DIGITAL_MARKETING]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.DIGITAL_MARKETING]:
      return TOPIC_KEY.DIGITAL_MARKETING;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.DRAWING]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.DRAWING]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.DRAWING]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.DRAWING]:
      return TOPIC_KEY.DRAWING;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.DRINKS]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.DRINKS]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.DRINKS]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.DRINKS]:
      return TOPIC_KEY.DRINKS;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.EDUCATION]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.EDUCATION]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.EDUCATION]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.EDUCATION]:
      return TOPIC_KEY.EDUCATION;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.ENEM]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.ENEM]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.ENEM]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.ENEM]:
      return TOPIC_KEY.ENEM;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.ENTREPRENEURSHIP]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.ENTREPRENEURSHIP]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.ENTREPRENEURSHIP]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.ENTREPRENEURSHIP]:
      return TOPIC_KEY.ENTREPRENEURSHIP;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.ESTHETICS]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.ESTHETICS]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.ESTHETICS]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.ESTHETICS]:
      return TOPIC_KEY.ESTHETICS;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.EXAM]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.EXAM]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.EXAM]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.EXAM]:
      return TOPIC_KEY.EXAM;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.EXCEL]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.EXCEL]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.EXCEL]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.EXCEL]:
      return TOPIC_KEY.EXCEL;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.FASHION]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.FASHION]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.FASHION]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.FASHION]:
      return TOPIC_KEY.FASHION;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.FINANCE]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.FINANCE]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.FINANCE]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.FINANCE]:
      return TOPIC_KEY.FINANCE;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.GASTRONOMY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.GASTRONOMY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.GASTRONOMY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.GASTRONOMY]:
      return TOPIC_KEY.GASTRONOMY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.HAIR]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.HAIR]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.HAIR]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.HAIR]:
      return TOPIC_KEY.HAIR;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.HANDICRAFT]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.HANDICRAFT]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.HANDICRAFT]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.HANDICRAFT]:
      return TOPIC_KEY.HANDICRAFT;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.HEALTHY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.HEALTHY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.HEALTHY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.HEALTHY]:
      return TOPIC_KEY.HEALTHY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.IDIOM]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.IDIOM]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.IDIOM]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.IDIOM]:
      return TOPIC_KEY.IDIOM;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.INVESTIMENTS]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.INVESTIMENTS]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.INVESTIMENTS]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.INVESTIMENTS]:
      return TOPIC_KEY.INVESTIMENTS;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.LAW]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.LAW]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.LAW]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.LAW]:
      return TOPIC_KEY.LAW;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.LIBRAS]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.LIBRAS]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.LIBRAS]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.LIBRAS]:
      return TOPIC_KEY.LIBRAS;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.LITERATURE]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.LITERATURE]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.LITERATURE]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.LITERATURE]:
      return TOPIC_KEY.LITERATURE;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.MAINTENANCE]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.MAINTENANCE]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.MAINTENANCE]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.MAINTENANCE]:
      return TOPIC_KEY.MAINTENANCE;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.MAKEUP]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.MAKEUP]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.MAKEUP]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.MAKEUP]:
      return TOPIC_KEY.MAKEUP;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.MANICURE]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.MANICURE]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.MANICURE]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.MANICURE]:
      return TOPIC_KEY.MANICURE;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.MATERNITY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.MATERNITY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.MATERNITY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.MATERNITY]:
      return TOPIC_KEY.MATERNITY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.MEDITATION]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.MEDITATION]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.MEDITATION]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.MEDITATION]:
      return TOPIC_KEY.MEDITATION;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.MUSIC]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.MUSIC]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.MUSIC]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.MUSIC]:
      return TOPIC_KEY.MUSIC;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.ORATORY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.ORATORY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.ORATORY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.ORATORY]:
      return TOPIC_KEY.ORATORY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.PERSONAL_DEVELOPMENT]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.PERSONAL_DEVELOPMENT]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.PERSONAL_DEVELOPMENT]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.PERSONAL_DEVELOPMENT]:
      return TOPIC_KEY.PERSONAL_DEVELOPMENT;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.PET]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.PET]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.PET]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.PET]:
      return TOPIC_KEY.PET;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.PHOTOGRAPHY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.PHOTOGRAPHY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.PHOTOGRAPHY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.PHOTOGRAPHY]:
      return TOPIC_KEY.PHOTOGRAPHY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.PHYSIOTHERAPY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.PHYSIOTHERAPY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.PHYSIOTHERAPY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.PHYSIOTHERAPY]:
      return TOPIC_KEY.PHYSIOTHERAPY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.PLANTS]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.PLANTS]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.PLANTS]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.PLANTS]:
      return TOPIC_KEY.PLANTS;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.POLITICS]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.POLITICS]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.POLITICS]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.POLITICS]:
      return TOPIC_KEY.POLITICS;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.PROGRAMMING]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.PROGRAMMING]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.PROGRAMMING]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.PROGRAMMING]:
      return TOPIC_KEY.PROGRAMMING;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.PSYCHOLOGY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.PSYCHOLOGY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.PSYCHOLOGY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.PSYCHOLOGY]:
      return TOPIC_KEY.PSYCHOLOGY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.RECIPE]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.RECIPE]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.RECIPE]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.RECIPE]:
      return TOPIC_KEY.RECIPE;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.RELATIONSHIP]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.RELATIONSHIP]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.RELATIONSHIP]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.RELATIONSHIP]:
      return TOPIC_KEY.RELATIONSHIP;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.SITE]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.SITE]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.SITE]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.SITE]:
      return TOPIC_KEY.SITE;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.SLIMMING]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.SLIMMING]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.SLIMMING]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.SLIMMING]:
      return TOPIC_KEY.SLIMMING;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.SPIRITUALITY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.SPIRITUALITY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.SPIRITUALITY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.SPIRITUALITY]:
      return TOPIC_KEY.SPIRITUALITY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.SPORTS]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.SPORTS]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.SPORTS]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.SPORTS]:
      return TOPIC_KEY.SPORTS;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.THEOLOGY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.THEOLOGY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.THEOLOGY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.THEOLOGY]:
      return TOPIC_KEY.THEOLOGY;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.TURISMO]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.TURISMO]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.TURISMO]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.TURISMO]:
      return TOPIC_KEY.TURISMO;
    case TOPIC_TRANSLATION['pt-br'][TOPIC_KEY.WORKPLACE_SAFETY]:
    case TOPIC_TRANSLATION.es[TOPIC_KEY.WORKPLACE_SAFETY]:
    case TOPIC_TRANSLATION.en[TOPIC_KEY.WORKPLACE_SAFETY]:
    case TOPIC_TRANSLATION.fr[TOPIC_KEY.WORKPLACE_SAFETY]:
      return TOPIC_KEY.WORKPLACE_SAFETY;
    default:
      return null;
  }
};

export const redirectCampaignToTopification = (campaign: string, language: string) => {
  if (language === 'es') {
    switch (campaign) {
      case CAMPAIGN_ES.COOKING_IN_PRACTICE:
        return getTopificationRouter({
          language: 'es',
          topification: TOPIFICATION_KEY.COOKING_AND_GASTRONOMY
        });
      case CAMPAIGN_ES.FASHION_AND_BEAUTY:
        return getTopificationRouter({
          language: 'es',
          topification: TOPIFICATION_KEY.FASHION_AND_BEAUTY
        });
      case CAMPAIGN_ES.YOUR_FINANCES:
        return getTopificationRouter({
          language: 'es',
          topification: TOPIFICATION_KEY.FINANCE_AND_BUSINESS,
          topic: TOPIC_KEY.FINANCE
        });
      default:
        return null;
    }
  } else if (language === 'pt-br') {
    switch (campaign) {
      case CAMPAIGN_PT_BR.HEALTH_AND_SPORTS:
        return getTopificationRouter({
          language: 'pt-br',
          topification: TOPIFICATION_KEY.HEALTH_AND_SPORTS
        });
      case CAMPAIGN_PT_BR.CHANGE_YOUR_CARRER:
        return getTopificationRouter({
          language: 'pt-br',
          topification: TOPIFICATION_KEY.CAREER_PERSONAL_DEVELOPMENT,
          topic: TOPIC_KEY.CARRER
        });
      case CAMPAIGN_PT_BR.MUSIC_STUDIO:
        return getTopificationRouter({
          language: 'pt-br',
          topification: TOPIFICATION_KEY.MUSIC_AND_ARTS,
          topic: TOPIC_KEY.MUSIC
        });
      case CAMPAIGN_PT_BR.COOKING_IN_PRACTICE:
        return getTopificationRouter({
          language: 'pt-br',
          topification: TOPIFICATION_KEY.COOKING_AND_GASTRONOMY
        });
      case CAMPAIGN_PT_BR.FASHION_AND_BEAUTY:
        return getTopificationRouter({
          language: 'pt-br',
          topification: TOPIFICATION_KEY.FASHION_AND_BEAUTY
        });
      case CAMPAIGN_PT_BR.YOUR_FINANCES:
        return getTopificationRouter({
          language: 'pt-br',
          topification: TOPIFICATION_KEY.FINANCE_AND_BUSINESS,
          topic: TOPIC_KEY.FINANCE
        });
      default:
        return null;
    }
  }

  return null;
};
