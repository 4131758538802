import { useContext, useEffect } from 'react';
import classnames from 'classnames';
import Cookie from 'js-cookie';
import { publicIpv4 } from 'public-ip';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { v4 as uuid } from 'uuid';
import { getRemoteAddr } from 'utils/string';
import { loadSearchData } from 'utils/search';
import { sendAnalyticsEvent } from 'utils/datalayer';
import * as DatahubService from 'utils/datahub';
import { EVENT_ACTIONS, SEARCH_SOURCES } from 'utils/datahub.constants';

import { topicPerTopification, topificationList, TOPIFICATION_KEY } from '@core/domain/constants/topification';
import { getTopificationRouter } from '@core/presentation/utils/topification';
import Link from 'next/link';
import Footer from '../Footer/Common';
import { StoreContext } from '../core/Provider/Store';
import { MarketplaceContext } from '../core/Provider/MarketplaceProvider';

const Header = dynamic(() => import('components/Header/Common'), {
  ssr: false
});

type Props = {
  className?: string,
  lng?: Language,
  children?: any,
  showMenu?: boolean,
  showFooter?: boolean,
  showSearch?: string,
  showSearchInput?: boolean,
  customLogo?: object,
  homeURL?: string,
  searchTerm?: string,
  showCategoryMenu?: boolean,
  searchPlaceholder?: string,
  seasonInfo?: string,
  tReady?: string,
  project?: string,
  environment?: string,
  isMobile?: boolean,
  hideTopification?: boolean,
};

function Body({
  className,
  lng,
  children,
  showMenu,
  showFooter = true,
  showSearch,
  showSearchInput,
  customLogo,
  homeURL,
  searchTerm,
  showCategoryMenu,
  searchPlaceholder,
  seasonInfo,
  tReady,
  project,
  environment,
  isMobile,
  hideTopification,
  ...props
}: Props) {
  const store = useContext(StoreContext);

  const marketplace = useContext(MarketplaceContext);

  useEffect(() => {
    showSearch = marketplace.showSearch;
  }, [marketplace]);


  const { t } = useTranslation('common');
  const searchData = loadSearchData(t, lng);

  const eventsAutocomplete = async (value) => {
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: 'Search:AutoComplete',
      event_action: `Click:AutoComplete:${value}`,
      event_label: value
    });

    DatahubService.sendEvent({
      action: EVENT_ACTIONS.SEARCH,
      locale: lng,
      term: `${(value && value?.url.split('?q=')[1]) || ''}`,
      ip: getRemoteAddr(await publicIpv4()),
      sessionId: Cookie.get('sid'),
      source: SEARCH_SOURCES.HEADER,
      path: window.location.href
    });
  };

  return (
    <main className={classnames('body-container', className)}>
      <div className="body" {...props}>
        <Header
          store={store}
          user={store.user}
          isMobile={isMobile}
          lng={lng}
          showSearchInput={showSearchInput}
          customLogo={customLogo}
          homeURL={homeURL}
          searchTerm={searchTerm}
          searchPlaceholder={searchPlaceholder || searchData.placeholder}
          showCategoryMenu={showCategoryMenu}
          campaignBanner={seasonInfo}
          searchSuggestions={searchData.suggestions}
          project={project}
          environment={environment}
          registerLabel={t('general.signup')}
          accessButtonsDirection="vertical"
          showHeaderSignInSignUp
          isMarketplace
          onSuggestionClick={async (suggestion) => {
            sendAnalyticsEvent({
              event: 'interaction',
              event_category: 'Marketplace:Search:Header',
              event_action: 'Click:Suggestions',
              event_label: `${(suggestion && suggestion.key) || ''}`
            });

            DatahubService.sendEvent({
              action: EVENT_ACTIONS.SEARCH,
              locale: lng,
              term: `${(suggestion && suggestion.value) || ''}`,
              ip: getRemoteAddr(await publicIpv4()),
              sessionId: Cookie.get('sid'),
              source: SEARCH_SOURCES.TAG,
              path: window.location.href
            });
          }}
          onAutoCompleteClick={eventsAutocomplete}
        />
        <div
          className={classnames('body__content', {
            'body__content--search-active': showSearch
          })}
        >
          {children}
        </div>
        <Footer
          lng={lng}
          user={store?.user}
          showFooter={showFooter}
          myShoppingLabel={t('general.my_shopping')}
          project={project}
          environment={environment}
        >
          {!hideTopification && (
            <>
              <h3 className="content-collapsed--title _text-gray-900 _text-5 _font-weight-extra-bold _pb-4 _mb-6 _mb-lg-9 _border-bottom">
                {t('general.all_products')}
              </h3>
              <ul className='topification__list topification__list--container'>
                {
                  topificationList.map(topification => {
                    const topificationUrl = getTopificationRouter({ language: lng, topification });
                    const topics = topicPerTopification[topification];

                    if (topification === TOPIFICATION_KEY.UNMAPPING_FIELD) return null;

                    return (
                      <li className="_my-1 _mb-5" key={uuid()}>
                        <Link href={topificationUrl}>
                          <a className="_text-gray-900 _text-2 _text-decoration-none _font-weight-extra-bold topification--title">
                            {t(`topification.${topification}`)}
                          </a>
                        </Link>
                        <ul className='topification__list'>
                          {topics.map((topic) => {
                            const topicUrl = getTopificationRouter({ language: lng, topification, topic });

                            return (
                              <li className='_my-2 topification__list-item' key={uuid()}>
                                <Link href={topicUrl}>
                                  <a className="_text-gray-900 _text-2 _text-decoration-none">{t(`topic.${topic}`)}</a>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })
                }
              </ul>
            </>
          )}

        </Footer>
      </div>
    </main>
  );
}

export default Body;
